<template>
  <base-modal v-model:open="isActive" hide-footer size="larger">
    <template v-slot:button>
      <button class="btn btn-primary">
        {{ $t('page.persona_generator.action_create_persona') }}
      </button>
    </template>

    <div class="card-body py-4 space-y-6 text-left">
      <persona-generator @close="toggle" />
    </div>

    <button
      class="btn btn-secondary absolute top-2 right-2"
      @click.prevent="toggle"
    >
      <i class="fas fa-times" style="margin: 0 !important"></i>
    </button>
  </base-modal>
</template>

<script lang="ts">
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import PersonaGenerator from '@/components/tools/personaGenerator/PersonaGenerator.vue'

export default defineComponent({
  components: {
    BaseModal,
    PersonaGenerator
  },

  setup () {
    const {
      isActive,
      toggle
    } = useToggle(false)

    return {
      isActive,
      toggle
    }
  }
})
</script>
