
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import PersonaGenerator from '@/components/tools/personaGenerator/PersonaGenerator.vue'

export default defineComponent({
  components: {
    BaseModal,
    PersonaGenerator
  },

  setup () {
    const {
      isActive,
      toggle
    } = useToggle(false)

    return {
      isActive,
      toggle
    }
  }
})
