import axios from 'axios'
import localforage from 'localforage'

const API_URL = 'https://us-central1-topic-generator-2f733.cloudfunctions.net/headlines'

const useCache = (cachetHeadlines: any) => {
  if (cachetHeadlines && cachetHeadlines.date) {
    const date: Date = cachetHeadlines.date
    return date.setDate(date.getDate() + 1) >= new Date().getTime()
  }
  return false
}

const toSlug = (keyWord: string) => {
  return keyWord
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '_')
    .toLowerCase()
}

export const fetch = async (keyWord: string, locale: string) => {

  const cachedData = await localforage.getItem(`cachedHeadlines-${toSlug(keyWord)}`) as any

  if (useCache(cachedData)) {
    return cachedData.data
  }

  try {
    const params = {
      term: keyWord,
      lang: locale.toLowerCase().slice(0, 2),
      limit: 365
    }
    const response = await axios.get(`${API_URL}`, {
      params
    })

    const result = response.data.map((title: string) => title.replace(keyWord, `<strong>${keyWord}</strong>`))

    await localforage.setItem(`cachedHeadlines-${toSlug(keyWord)}`, {
      data: result,
      date: new Date()
    })

    return result
  } catch (error) {
    console.log(error)
    // @ts-ignore
    throw new Error(error.message)
  }
}
