
import BaseModal from '@/components/base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { BaseModal },
  props: {
    persona: {
      type: Object,
      default: () => ({})
    },
  },

  events: ['remove'],

  setup (props, { emit }) {
    const {
      isActive,
      toggle
    } = useToggle(false)

    const remove = (index: number) => {
      emit('remove', index)
      toggle()
    }

    return {
      isActive,
      toggle,
      remove
    }
  }
})
