<template>
  <div class="card pt-4">
    <div class="card-body">
      <div
        class="flex flex-col lg:flex-row lg:space-x-4 h-full overflow-y-auto lg:overflow-y-hidden
      ">
        <div class="w-full lg:w-1/2 lg:overflow-y-auto">
          <div class="card-header p-0 mb-8">
            <div>
              <div class="card-title">
                {{ $t('page.tools.whatsapp_link.title') }}
              </div>
              <small>
                {{ $t('page.whatsapp_link.page_subtitle') }}
              </small>
            </div>
          </div>

          <form
            class="flex flex-col space-y-8 mb-8"
            @submit.prevent="generate"
          >
            <base-input
              :label="`1 - ${$t('form.whatsapp_link.phone_label')}`"
              name="phone"
              type="text"
              v-model="phone"
              autocomplete="off"
              placeholder="+55 (11) 9 9999 9999"
              left-icon="fab fa-whatsapp"
              :mask="phoneMask"
            />

            <base-textarea
              :label="`2 - ${$t('form.whatsapp_link.message_label')}`"
              name="message"
              rows="5"
              autocomplete="off"
              :autofocus="false"
              v-model="message"
              :placeholder="$t('form.whatsapp_link.message_placleholder')"
            />

            <button
              class="btn btn-primary mx-auto"
              type="submit"
              :disabled="!isValid"
            >
              <i class="fas fa-spinner fa-spin" v-if="isLoading"></i>
              {{ $t('form.whatsapp_link.action_submit') }}
            </button>
          </form>
        </div>
        <div class="w-full lg:w-1/2 lg:overflow-y-auto">
          <div v-if="whatsappLinks.length">
            <div class="font-semibold mb-4">
              {{ $t('page.tools.whatsapp_link.sentence_links_title') }}
            </div>

            <whatsapp-links
              :links="whatsappLinks"
              @remove="remove"
              can-remove
            />
          </div>
          <div v-else>
            <div class="text-xl font-semibold text-gray-700 mb-4">
              {{ $t('page.tools.whatsapp_link.sentence_no_links_title') }}
            </div>
            <div
              v-html="$t('page.tools.whatsapp_link.sentence_no_links-html')"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseInput from '@/components/base/BaseInput.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import { useWhatsappLink } from '@/composables/tools/useWhatsappLink'
import { computed, defineComponent, ref, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import WhatsappLinks from './whatsappLinks.vue'

export default defineComponent({
  components: { BaseInput, BaseTextarea, WhatsappLinks },
  setup () {
    const phone = ref('')
    const message = ref('')
    const isLoading = ref<boolean>(false)

    const { locale } = useI18n()

    const {
      addWhatsappLink,
      removeWhatsappLink,
      whatsappLinks
    } = useWhatsappLink()

    const generate = async () => {
      if (!unref(isValid)) {
        return
      }
      isLoading.value = true
      await addWhatsappLink(unref(phone), unref(message))
      phone.value = ''
      message.value = ''
      isLoading.value = false
    }

    const remove = async (index: number) => {
      await removeWhatsappLink(index)
    }

    const phoneMask = computed(() => {
      if (locale.value !== 'pt-br') {
        return ''
      }
      return ['+## (##) ####-####', '+## (##) #####-####']
    })

    const isValid = computed(() => {
      if (locale.value === 'pt-br') {
        return unref(phone).replace(/\D/g, '').length >= 12
      }
      return true
    })
    
    return {
      phone,
      message,
      phoneMask,
      isValid,
      isLoading,
      whatsappLinks,
      generate,
      remove
    }
  }
})
</script>
