
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { fetchForm, fetchAvatars } from '@/services/manycontentTools/personaGenerator'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import Multiselect from '@vueform/multiselect'
import { usePersonaGenerator } from '@/composables/tools/usePersonaGenerator'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { BaseInput, BaseTextarea, Multiselect },

  events: ['close'],

  setup (props, { emit }) {
    const avatars = ref([])
    const avatar = ref('')
    const form = ref([])
    const isLoading = ref(false)

    const { locale } = useI18n()

    const {
      addPersona
    } = usePersonaGenerator()

    const isValid = computed(() => {
      const emptyFormField = unref(form)
        .find((field: { response: string }) => !field.response || !field.response.trim())
      
      return !emptyFormField && unref(avatar)
    })

    onMounted(async () => {
      form.value = await fetchForm(unref(locale))
      avatars.value = await fetchAvatars()

      console.log(form.value)
    })

    const handleFormSubmit = async () => {
      if (!unref(isValid)) {
        return
      }

      isLoading.value = true

      const responses = unref(form).map((field: { label: string; response: string }) => ({
        question: field.label,
        response: field.response.trim()
      }))

      await addPersona({
        avatar: unref(avatar),
        responses
      })

      isLoading.value = false

      emit('close')
    }

    return {
      isLoading,
      avatars,
      avatar,
      form,
      isValid,
      handleFormSubmit
    }
  }
})
