
import { useToast } from '@/composables/useToast'
import { useClipboard } from '@vueuse/core'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    links: {
      type: Array,
      default: () => ([])
    },
    canRemove: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'remove'
  ],

  setup (props, { emit }) {
    const { t } = useI18n()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const handleCopy = (index: number) => {
      const whatsappLink = props.links[index] as { link: string }
      copy(whatsappLink.link)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const remove = async (index: number) => {
      emit('remove', index)
    }
    
    return {
      isCopySupported,
      handleCopy,
      remove
    }
  }
})
