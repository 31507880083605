import { computed, onBeforeMount, toRaw, unref } from 'vue'
import { useStore } from 'vuex'
import { useProfile } from '../profile/useProfile'

export const useTitleGenerator = () => {
  const store = useStore()

  const {
    profile
  } = useProfile()

  onBeforeMount(() => {
    const isLoaded = store.state.tools.isLoaded
    if (unref(profile) && unref(profile).id && !isLoaded) {
      store.dispatch('tools/fetch', unref(profile).id)
    }
  })

  const fetchTitles = async () => {
    if (unref(profile) && unref(profile).id) {
      store.dispatch('tools/fetch', unref(profile).id)
    }
  }

  const titles = computed(() => {
    return toRaw(store.getters['tools/titles'])
  })

  const addTitle = (title: string) => {
    return store.dispatch('tools/addTitle', {
      profileId: unref(profile).id,
      title
    })
  }

  const removeTitle = (titleIndex: number) => {
    return store.dispatch('tools/removeTitle', {
      profileId: unref(profile).id,
      titleIndex
    })
  }

  return {
    titles,
    fetchTitles,
    addTitle,
    removeTitle
  }
}
