import { computed, onBeforeMount, unref } from 'vue'
import { useStore } from 'vuex'
import { useProfile } from '../profile/useProfile'

export const usePersonaGenerator = () => {
  const store = useStore()

  const {
    profile
  } = useProfile()

  onBeforeMount(() => {
    const isLoaded = store.state.tools.isLoaded
    if (unref(profile) && unref(profile).id && !isLoaded) {
      store.dispatch('tools/fetch', unref(profile).id)
    }
  })

  const fetchPersonas = async () => {
    if (unref(profile) && unref(profile).id) {
      store.dispatch('tools/fetch', unref(profile).id)
    }
  }

  const personas = computed(() => {
    return store.state.tools.personas
  })

  const addPersona = (persona: { avatar: string, responses: any }) => {
    return store.dispatch('tools/addPersona', {
      profileId: unref(profile).id,
      persona
    })
  }

  const removePersona = (personaIndex: number) => {
    return store.dispatch('tools/removePersona', {
      profileId: unref(profile).id,
      personaIndex
    })
  }

  return {
    personas,
    fetchPersonas,
    addPersona,
    removePersona
  }
}
