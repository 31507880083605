
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ToolsCardHeader from '@/components/tools/ToolsCardHeader.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { DashboardContent, ToolsCardHeader },
  setup () {
    return {}
  }
})
