
import BaseInput from '@/components/base/BaseInput.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import { useWhatsappLink } from '@/composables/tools/useWhatsappLink'
import { computed, defineComponent, ref, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import WhatsappLinks from './whatsappLinks.vue'

export default defineComponent({
  components: { BaseInput, BaseTextarea, WhatsappLinks },
  setup () {
    const phone = ref('')
    const message = ref('')
    const isLoading = ref<boolean>(false)

    const { locale } = useI18n()

    const {
      addWhatsappLink,
      removeWhatsappLink,
      whatsappLinks
    } = useWhatsappLink()

    const generate = async () => {
      if (!unref(isValid)) {
        return
      }
      isLoading.value = true
      await addWhatsappLink(unref(phone), unref(message))
      phone.value = ''
      message.value = ''
      isLoading.value = false
    }

    const remove = async (index: number) => {
      await removeWhatsappLink(index)
    }

    const phoneMask = computed(() => {
      if (locale.value !== 'pt-br') {
        return ''
      }
      return ['+## (##) ####-####', '+## (##) #####-####']
    })

    const isValid = computed(() => {
      if (locale.value === 'pt-br') {
        return unref(phone).replace(/\D/g, '').length >= 12
      }
      return true
    })
    
    return {
      phone,
      message,
      phoneMask,
      isValid,
      isLoading,
      whatsappLinks,
      generate,
      remove
    }
  }
})
