
import { useToast } from '@/composables/useToast'
import { useClipboard } from '@vueuse/core'
import { defineComponent, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTitleGenerator } from '@/composables/tools/useTitleGenerator'

export default defineComponent({
  setup () {
    const { t } = useI18n()

    const {
      titles: headlines,
      removeTitle
    } = useTitleGenerator()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const handleCopy = (index: number) => {
      const sentence = unref(headlines)[index].replace(/(<([^>]+)>)/gi, "")
      copy(sentence)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    return {
      headlines,
      isCopySupported,
      handleCopy,
      removeTitle
    }
  }
})
