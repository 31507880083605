import axios from 'axios'
import localforage from 'localforage'

const API_URL = 'https://us-central1-topic-generator-2f733.cloudfunctions.net/persona'

const useCache = (cachedData: any) => {
  if (cachedData && cachedData.date) {
    const date: Date = cachedData.date
    return date.setDate(date.getDate() + 1) >= new Date().getTime()
  }
  return false
}

export const fetchForm = async (locale: string) => {
  const cachedData = await localforage.getItem(`cachedPersonaForm`) as any

  if (useCache(cachedData)) {
    return cachedData.data
  }

  try {
    const params = {
      lang: locale.toLowerCase().slice(0, 2)
    }

    const response = await axios.get(`${API_URL}/forms`, {
      params
    })

    const form = response.data[0].data

    await localforage.setItem(`cachedPersonaForm`, {
      data: form,
      date: new Date()
    })

    return form
  } catch (error) {
    console.log(error)
    // @ts-ignore
    throw new Error(error.message)
  }
}

export const fetchAvatars = async () => {
  const cachedData = await localforage.getItem(`cachedPersonaAvatars`) as any

  if (useCache(cachedData)) {
    return cachedData.data
  }

  try {
    const response = await axios.get(`${API_URL}/avatars`)
    
    const avatars = response.data.map((avatar: { image: string }) => avatar.image)

    await localforage.setItem(`cachedPersonaAvatars`, {
      data: avatars,
      date: new Date()
    })

    return avatars
  } catch (error) {
    console.log(error)
    // @ts-ignore
    throw new Error(error.message)
  }
}
