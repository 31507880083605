<template>
  <dashboard-content>
    <template v-slot:actions>
      <title-generator-modal />
    </template>

    <my-titles />
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import MyTitles from '@/components/tools/titleGenerator/MyTitles.vue'
import TitleGeneratorModal from '@/components/tools/titleGenerator/TitleGeneratorModal.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    DashboardContent,
    TitleGeneratorModal,
    MyTitles
  },
})
</script>
