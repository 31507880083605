<template>
  <div
    class="card p-4 flex flex-row justify-start items-center"
  >
    <img class="h-24" :src="persona.avatar">
    <div class="w-full">
      <div class="font-semibold">{{ persona.responses[0].response }}</div>
      <div class="text-gray-500">{{ persona.responses[1].response }}</div>
      <div class="flex justify-end">
        <base-modal v-model:open="isActive" hide-footer size="medium">
          <template v-slot:button>
            <button class="btn btn-secondary">
              {{ $t('action.open') }}
            </button>
          </template>

          <div class="card-body pt-4">
            <div class="flex flex-row items-center">
              <div class="w-2/12">
                <img class="h-24 mx-auto" :src="persona.avatar">
              </div>
              <div class="w-10/12">
                <div class="font-semibold">{{ persona.responses[0].response }}</div>
                <div class="text-gray-500">{{ persona.responses[1].response }}</div>
              </div>
            </div>
            <div class="my-8">
              <hr>
            </div>
            <div
              class="flex flex-col justify-end space-y-4 w-full lg:w-10/12 lg:ml-auto"
            >
              <div
                v-for="(question, index) in persona.responses"
                :key="index"
              >
                <div class="font-semibold">{{ question.question }}</div>
                <div>{{ question.response }}</div>
              </div>
            </div>
          </div>

          <div class="card-footer flex justify-end space-x-3">
            <button class="btn btn-secondary" @click.prevent="remove(index)">
              <i class="fa fa-trash"></i>
              {{ $t('action.delete_persona') }}
            </button>

            <button class="btn btn-primary" @click.prevent="toggle">
              {{ $t('action.close') }}
            </button>
          </div>

          <button
            class="btn btn-secondary absolute top-2 right-2"
            @click.prevent="toggle"
          >
            <i class="fas fa-times" style="margin: 0 !important"></i>
          </button>
        </base-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseModal from '@/components/base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { BaseModal },
  props: {
    persona: {
      type: Object,
      default: () => ({})
    },
  },

  events: ['remove'],

  setup (props, { emit }) {
    const {
      isActive,
      toggle
    } = useToggle(false)

    const remove = (index: number) => {
      emit('remove', index)
      toggle()
    }

    return {
      isActive,
      toggle,
      remove
    }
  }
})
</script>