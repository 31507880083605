<template>
  <div
    class="flex flex-col lg:flex-row lg:space-x-4 h-full overflow-y-auto lg:overflow-y-hidden"
  >
    <div class="w-full lg:w-2/3 lg:overflow-y-auto">
      <div
        class="text-center text-2xl my-8"
        v-html="$t('page.tools.persona_generator.description-html')"
      />
      <div class="text-center text-sm">
        {{ $t('page.persona_generator.form.helper_message') }}
      </div>
      <form
        class="flex flex-col space-y-4 w-full lg:w-10/12 my-10 mx-auto"
        @submit.prevent="handleFormSubmit"
      >
        <label>{{ $t('page.persona_generator.label_select_avatar') }}</label>
        <div
          class="grid grid-cols-4 lg:grid-cols-8 gap-2"
        >
          <div
            class="card p-1 hover:bg-indigo-50 cursor-pointer"
            :class="{ 'bg-indigo-50 ring-2 ring-indigo-400': image === avatar }"
            v-for="(image, index) in avatars"
            :key="index"
            @click="avatar = image"
          >
            <img
              :src="image"
            />
          </div>
        </div>
        <div
          v-for="(field, index) in form"
          :key="index"
        >
          <div v-if="['text', 'number'].includes(field.type)">
            <base-input
              :label="field.label"
              :name="`field-${index}`"
              :type="field.type"
              v-model="field['response']"
              autocomplete="off"
              :placeholder="field.placeholder"
            />
          </div>
          <div v-if="field.type === 'textarea'">
            <base-textarea
              :label="field.label"
              :name="`field-${index}`"
              rows="5"
              autocomplete="off"
              :autofocus="false"
              v-model="field['response']"
              :placeholder="field.placeholder"
            />
          </div>
          <div v-if="field.type === 'select'">
            <label for="ategory-select">{{ field.label }}</label>
            <Multiselect
              :id="`field-${index}`"
              :name="`field-${index}`"
              valueProp="uid"
              v-model="field['response']"
              :options="field.value.options"
              :searchable="false"
              :placeholder="$t('page.settings.category.form.category.placeholder')"
              :noResultsText="$t('page.settings.category.form.category.no_results')"
              :noOptionsText="$t('page.settings.category.form.category.no_options')"
            />
          </div>
        </div>

        <button
          class="btn btn-primary mx-auto"
          type="submit"
          :disabled="!isValid"
        >
          <i class="fas fa-spinner fa-spin" v-if="isLoading"></i>
          {{ $t('form.persona_generator.action_submit') }}
        </button>
      </form>
    </div>
    <div class="w-full lg:w-1/3 mt-12 lg:mt-0 lg:overflow-y-auto">
      <div class="font-semibold text-indigo-700 text-xl mt-4">
        {{ $t('page.persona_generator.herlp_title_1') }}
      </div>
      <div class="mb-8">
        {{ $t('page.persona_generator.herlp_text_1') }}
      </div>

      <div class="font-semibold text-indigo-700 text-xl">
        {{ $t('page.persona_generator.herlp_title_2') }}
      </div>
      <div class="mb-8">
        {{ $t('page.persona_generator.herlp_text_2') }}
      </div>

      <div class="font-semibold text-indigo-700 text-xl">
        {{ $t('page.persona_generator.herlp_title_3') }}
      </div>
      <div class="mb-8">
        {{ $t('page.persona_generator.herlp_text_3') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { fetchForm, fetchAvatars } from '@/services/manycontentTools/personaGenerator'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import Multiselect from '@vueform/multiselect'
import { usePersonaGenerator } from '@/composables/tools/usePersonaGenerator'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { BaseInput, BaseTextarea, Multiselect },

  events: ['close'],

  setup (props, { emit }) {
    const avatars = ref([])
    const avatar = ref('')
    const form = ref([])
    const isLoading = ref(false)

    const { locale } = useI18n()

    const {
      addPersona
    } = usePersonaGenerator()

    const isValid = computed(() => {
      const emptyFormField = unref(form)
        .find((field: { response: string }) => !field.response || !field.response.trim())
      
      return !emptyFormField && unref(avatar)
    })

    onMounted(async () => {
      form.value = await fetchForm(unref(locale))
      avatars.value = await fetchAvatars()

      console.log(form.value)
    })

    const handleFormSubmit = async () => {
      if (!unref(isValid)) {
        return
      }

      isLoading.value = true

      const responses = unref(form).map((field: { label: string; response: string }) => ({
        question: field.label,
        response: field.response.trim()
      }))

      await addPersona({
        avatar: unref(avatar),
        responses
      })

      isLoading.value = false

      emit('close')
    }

    return {
      isLoading,
      avatars,
      avatar,
      form,
      isValid,
      handleFormSubmit
    }
  }
})
</script>
