
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import WhatsappLink from '@/components/tools/whatsappLink/whatsappLink.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    DashboardContent,
    WhatsappLink
  },
})
