<template>
  <div
    class="flex flex-col lg:flex-row lg:space-x-4 h-full overflow-y-auto lg:overflow-y-hidden"
  >
    <div class="w-full lg:w-2/3 lg:overflow-y-auto">
      <div
        class="text-center text-2xl my-8"
        v-html="$t('page.tools.title-generator.description-html')"
      />
      <div class="text-center text-sm">
        {{ $t('page.title_generator.form.helper_message') }}
      </div>
      <form
        class="flex items-center relative w-full lg:w-10/12 my-10 mx-auto"
        @submit.prevent="fetchHeadlines"
      >
        <i class="fas fa-search text-gray-500 absolute ml-4"></i>
        <input
          class="pl-10 py-4 w-full border rounded focus:bg-gray-50"
          type="text"
          v-model="keyword"
          :placeholder="$t('page.title_generator.form.search.placeholder')"
        />
        <button
          class="btn btn-primary absolute right-4"
          type="submit"
          :disabled="!isValidKeyword"
        >
          {{ $t('action.search') }}
        </button>
      </form>
      <div
        class="text-center my-24"
        v-if="isLoading"
      >
        <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
      </div>
      <div
        class="text-center"
        v-if="hasError && !headlines.length"
      >
        <p
          class="text-red-800"
          v-html="$t('page.title_generator.serch_error_message-html')"
        />
        <div class="text-sm text-center mt-12">
          <base-you-need-help-message />
        </div>
      </div>
      <div
        class="grid grid-cols-1 divide-y bg-gray-100 divide-gray-300"
        v-if="headlines.length"
      >
        <div
          class="py-2 px-4 flex justify-between items-center"
          v-for="(headline, index) in headlines"
          :key="index"
        >
          <div
            v-html="headline"
          />
          <button
            class="btn"
            @click="save(index)"
          >
            {{ $t('action.save') }}
          </button>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-1/3 mt-12 lg:mt-0 lg:overflow-y-auto">
      <ul class="flex flex-col my-4 space-y-8">
        <li>
          1 - {{ $t('page.title_generator.helper_step_1') }}
          <div class="bg-gray-100 rounded flex justify-between items-center px-3 py-2 mx-auto mt-4 w-64">
            {{ $t('page.title_generator.sentence_keyword') }}
            <div class="bg-blue-500 rounded px-2 py-1">
              <i class="fas fa-search text-white"></i>
            </div>
          </div>
        </li>
        <li>
          2 - {{ $t('page.title_generator.helper_step_2') }}
          <div class="flex flex-col space-y-1 mt-4 mx-auto w-64">
            <div
              class="bg-gray-100 rounded flex justify-between items-center px-3 py-2"
              v-for="i in 3"
              :key="i"
            >
              <div class="bg-gray-400 rounded-2xl w-44 h-3"></div>
              <div class="bg-blue-500 rounded w-8 h-4">
              </div>
            </div>
          </div>
        </li>
        <li>
          3 - {{ $t('page.title_generator.helper_step_3') }}
          <div
            class="bg-gray-200 rounded flex flex-col p-1 mx-auto mt-4 w-64"
          >
            <div class="flex flex-row justify-start space-x-1 w-full my-1">
              <div class="bg-red-600 text-4xl rounded-full w-2 h-2"></div>
              <div class="bg-yellow-600 text-4xl rounded-full w-2 h-2"></div>
              <div class="bg-green-600 text-4xl rounded-full w-2 h-2"></div>
            </div>
            <div class="flex flex-col items-center py-2 bg-white h-32">
              <div class="text-xs mb-2 font-semibold uppercase">
                {{ $t('page.title_generator.sentence_headline') }}
              </div>
              <div
                class="bg-gray-400 rounded-2xl w-32 h-1 mb-2"
                v-for="i in 6"
                :key="i"
              ></div>

            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref } from 'vue'
import { fetch } from '@/services/manycontentTools/titleGenetator'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'
import { useTitleGenerator } from '@/composables/tools/useTitleGenerator'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: {
    BaseYouNeedHelpMessage,
  },
  setup () {
    const keyword = ref('')
    const headlines = ref<string[]>([])
    const hasError = ref<boolean>(false)
    const isLoading = ref<boolean>(false)

    const {
      addTitle
    } = useTitleGenerator()

    const { t, locale } = useI18n()

    const fetchHeadlines = async () => {
      if (!unref(isValidKeyword)) {
        return
      }
      try {
        hasError.value = false
        headlines.value = []
        isLoading.value = true
        headlines.value = await fetch(unref(keyword), unref(locale))
      } catch (error) {
        hasError.value = true
      } finally {
        isLoading.value = false
      }
    }

    const save = async (index: number) => {
      await addTitle(unref(headlines)[index])
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const isValidKeyword = computed(() => {
      return unref(keyword).length >= 2
    })

    return {
      keyword,
      headlines,
      isValidKeyword,
      hasError,
      isLoading,
      save,
      fetchHeadlines
    }
  }
})
</script>
