
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import MyTitles from '@/components/tools/titleGenerator/MyTitles.vue'
import TitleGeneratorModal from '@/components/tools/titleGenerator/TitleGeneratorModal.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    DashboardContent,
    TitleGeneratorModal,
    MyTitles
  },
})
