<template>
  <div class="flex flex-col space-y-4">
    <my-persona
      v-for="(persona, index) in personas"
      :key="index"
      :persona="persona"
      @remove="handleRemove(index)"
    />
  </div>
</template>

<script lang="ts">
import { usePersonaGenerator } from '@/composables/tools/usePersonaGenerator'
import { defineComponent } from 'vue'
import MyPersona from './MyPersona.vue'

export default defineComponent({
  components: { MyPersona },
  setup () {
    const {
      personas,
      removePersona
    } = usePersonaGenerator()

    const handleRemove = async (index: number) => {
      await removePersona(index)
    }

    return {
      personas,
      handleRemove
    }
  }
})
</script>
