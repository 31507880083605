import { computed, onBeforeMount, unref } from 'vue'
import { useStore } from 'vuex'
import { useProfile } from '../profile/useProfile'

export const useWhatsappLink = () => {
  const store = useStore()

  const {
    profile
  } = useProfile()

  onBeforeMount(() => {
    const isLoaded = store.state.tools.isLoaded
    if (unref(profile) && unref(profile).id && !isLoaded) {
      store.dispatch('tools/fetch', unref(profile).id)
    }
  })

  const fetchWhatsappLinks = async () => {
    if (unref(profile) && unref(profile).id) {
      store.dispatch('tools/fetch', unref(profile).id)
    }
  }

  const whatsappLinks = computed(() => {
    return store.state.tools.whatsappLinks
  })

  const addWhatsappLink = async (phone: string, message: string) => {
    await store.dispatch('tools/addWhatsappLink', {
      profileId: unref(profile).id,
      phone,
      message
    })
  }

  const removeWhatsappLink = async (whatsappLinkIndex: number) => {
    await store.dispatch('tools/removeWhatsappLink', {
      profileId: unref(profile).id,
      whatsappLinkIndex
    })
  }

  return {
    whatsappLinks,
    fetchWhatsappLinks,
    addWhatsappLink,
    removeWhatsappLink
  }
}
