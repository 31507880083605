
import { computed, defineComponent, ref, unref } from 'vue'
import { fetch } from '@/services/manycontentTools/titleGenetator'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'
import { useTitleGenerator } from '@/composables/tools/useTitleGenerator'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: {
    BaseYouNeedHelpMessage,
  },
  setup () {
    const keyword = ref('')
    const headlines = ref<string[]>([])
    const hasError = ref<boolean>(false)
    const isLoading = ref<boolean>(false)

    const {
      addTitle
    } = useTitleGenerator()

    const { t, locale } = useI18n()

    const fetchHeadlines = async () => {
      if (!unref(isValidKeyword)) {
        return
      }
      try {
        hasError.value = false
        headlines.value = []
        isLoading.value = true
        headlines.value = await fetch(unref(keyword), unref(locale))
      } catch (error) {
        hasError.value = true
      } finally {
        isLoading.value = false
      }
    }

    const save = async (index: number) => {
      await addTitle(unref(headlines)[index])
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const isValidKeyword = computed(() => {
      return unref(keyword).length >= 2
    })

    return {
      keyword,
      headlines,
      isValidKeyword,
      hasError,
      isLoading,
      save,
      fetchHeadlines
    }
  }
})
