<template>
  <dashboard-content>
    <div
      class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"
    >
      <router-link :to="{ name: 'tools-title-generator' }">
        <div class="card card-hover flex flex-col items-start justify-center h-full">
          <tools-card-header
            icon="title-generator"
            :title="$t('page.tools.title-generator.title')"
            bgColor="bg-indigo-500"
          />
          <div class="card-body pt-4 text-left">
            <p
              v-html="$t('page.tools.title-generator.description-html')"
            />
          </div>
        </div>
      </router-link>

      <router-link :to="{ name: 'tools-whatsapp-link' }">
        <div class="card card-hover flex flex-col items-start justify-center h-full">
          <tools-card-header
            icon="whatsapp-link"
            :title="$t('page.tools.whatsapp_link.title')"
            bgColor="bg-green-500"
          />
          <div class="card-body pt-4 text-left">
            <p
              v-html="$t('page.tools.whatsapp_link.description-html')"
            />
          </div>
        </div>
      </router-link>

      <router-link :to="{ name: 'tools-persona-generator' }">
        <div class="card card-hover flex flex-col items-start justify-center h-full">
          <tools-card-header
            icon="persona-generator"
            :title="$t('page.tools.persona_generator.title')"
            bgColor="bg-red-600"
          />
          <div class="card-body pt-4 text-left">
            <p
              v-html="$t('page.tools.persona_generator.description-html')"
            />
          </div>
        </div>
      </router-link>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ToolsCardHeader from '@/components/tools/ToolsCardHeader.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { DashboardContent, ToolsCardHeader },
  setup () {
    return {}
  }
})
</script>

<style scoped>
@layer components {
  a {
    @apply hover:no-underline;
  }
}
</style>
