
import { usePersonaGenerator } from '@/composables/tools/usePersonaGenerator'
import { defineComponent } from 'vue'
import MyPersona from './MyPersona.vue'

export default defineComponent({
  components: { MyPersona },
  setup () {
    const {
      personas,
      removePersona
    } = usePersonaGenerator()

    const handleRemove = async (index: number) => {
      await removePersona(index)
    }

    return {
      personas,
      handleRemove
    }
  }
})
