<template>
  <dashboard-content>
    <template v-slot:actions>
      <persona-generator-modal />
    </template>

    <my-personas />
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import MyPersonas from '@/components/tools/personaGenerator/MyPersonas.vue'
import PersonaGeneratorModal from '@/components/tools/personaGenerator/PersonaGeneratorModal.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    DashboardContent,
    MyPersonas,
    PersonaGeneratorModal
  },
})
</script>
