
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import TitleGenerator from '@/components/tools/titleGenerator/TitleGenerator.vue'

export default defineComponent({
  components: {
    BaseModal,
    TitleGenerator
  },

  setup () {
    const {
      isActive,
      toggle
    } = useToggle(false)
    

    return {
      isActive,
      toggle
    }
  }
})
